'use strict';

angular.module('windmanagerApp')
  .directive('openClose', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        $(element).openClose(scope.$eval(attrs.openClose));
      }
    };
  });
